<template>
  <div class="taskShowView">
    <van-sticky>
      <van-nav-bar :title="$t('任务详情')" left-arrow @click-left="onClickLeft" />
    </van-sticky>

   <div class="video-container" v-if="detail.status==0">
      <video
        ref="videoPlayer"
        :controls="showControls"  
        @play="handlePlay"
        @pause="handlePause"
        @ended="handleEnded"
        @timeupdate="updateCurrentTime"
      >
        <source :src="videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        <button @click="playVideo" v-if="!isPlaying" class="play-button"></button>
   </div>
    <div class="title">
      <h4>{{$t('电影名称')}}</h4>
      <p v-html="detail.title"></p>
      
      <h4>{{$t('影片介绍')}}</h4>
      <p v-html="detail.content"></p>      
      <img :src="dynamicImage" v-if="detail.status != 0" />
    </div>
  
    <div class="details">

      <van-row v-if="detail.status==0">
        <van-col span="12">{{$t('任务要求')}}</van-col>
        <van-col span="12">{{$t('奖励金额')}}</van-col>
      </van-row>
      <van-row v-if="detail.status==0">
        <van-col span="12">{{$t('请观看30秒')}}</van-col>
        <van-col span="12">円{{ detail.price }}</van-col>
      </van-row>
       <dd class="normal_info" v-if="detail.status!=0"><label>{{$t('任务收益')}}：</label><span style="color:#4087f1">+円{{ detail.price }}</span></dd>
       <dd class="normal_info" v-if="detail.status!=0"><label>{{$t('核定说明')}}：</label><span>{{ detail.admin_remark }}</span></dd>
       <dd class="normal_info" v-if="detail.status!=0"><label>{{$t('审核日期')}}：</label><span>{{ $func.formattedDate(detail.shenhe_time)  }}</span></dd>
    </div>

  </div>
</template>
<script>
import { getTaskOrderDetail, orderSubmit  } from "@/api/task";

export default {
  data() {
    return {
      showControls:false,
      isPlaying: false,
      currentTime: 0,
      videoUrl: '', // 视频链接初始化为空
      id: this.$route.params.id,
      detail: {"price":"0.00","status":0,"admin_remark":""},
      buyShow: false,
      count: 1,
      money: this.$store.getters.userinfo.money,
      money1: this.$store.getters.userinfo.money1,
      pay_type: "1",
      result: {},
      active: 0,
      disabled: false
    };
  },
  created() {

    this.GetTaskDetail();
  },
  computed: {
    dynamicImage() {
      if (this.detail.status != 0) {
        return   require(`@/assets/images/state${this.detail.status}-${this.$lang}.png`)
      }
      return ''; // 返回空字符串以避免错误
    },
  },  
  watch: {
    videoUrl(newUrl) {
      // 在 videoUrl 改变时重新加载视频
      this.$refs.videoPlayer.load();
    },
  },
  methods: {
    playVideo() {
      this.$refs.videoPlayer.play();
      this.showControls = true;
    },    
    handlePlay() {
     this.isPlaying = true;
    },
    handlePause() {
      this.showControls = false;
      this.isPlaying = false;
    },
    handleEnded() {
          orderSubmit({ id: this.id})
            .then(response => {
                setTimeout(() => {
                  this.$router.push({ path: `/main/task/1}` });
                }, 500);
            })
            .catch(error => {});
    },
    updateCurrentTime() {
      this.currentTime = Math.floor(this.$refs.videoPlayer.currentTime);
    },

    onClose() {
      this.result = {};
      this.pay_type = "1";
    },

    onClickLeft() {
      this.$router.go(-1);
    },
    //获取商品详情
    GetTaskDetail() {
      getTaskOrderDetail({ id: this.id })
        .then(response => {
          this.detail = response.result;
          this.disabled = this.detail.disabled;
              this.videoUrl = this.$imageUrl + this.detail.video_url;
        })
        .catch(error => {});
    }
  }
};
</script>
<style lang="stylus">
.taskShowView
  .normal_info
    display: flex;
    align-items: center;
    padding: 0.2rem 0.4rem 0.1rem
    overflow: hidden;
    color: #ffffff;
    font-size: 0.32rem;
    label 
      font-weight: 600;
      white-space: nowrap;
    span 
      flex: 1;  
  .video-container
   position: relative; 
   video 
     display: block; /* 使视频块元素 */
     width: 100%;
   .play-button 
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%); /* 居中 */
     width: 2rem;
     height: 1.4rem;
     background-color: rgba(153, 151, 151, 0.5); /* 半透明背景 */
     border-radius: 0.2rem; /* 圆形 */
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     border: 1px solid #fff;
   .play-button::before
    content: '';
    border-top: 0.22rem solid transparent; /* 上边框透明 */
    border-bottom: 0.22rem solid transparent; /* 下边框透明 */
    border-left: 0.35rem solid white; /* 左边框为白色，形成三角形 */
    display: inline-block;
  .title 
    padding:0.15rem 0.4rem 0.4rem;
    background-color: #151d31;
    color: #ffffff;
    font-size: 0.32rem;
    position:relative
    img 
      position:absolute
      right: 0.2rem;
      top: 0.3rem;
      width: 2.5rem;
    h4 
      margin-top: 0.2rem;
      font-weight: normal;
      margin-bottom: 0.2rem;
    p 
      line-height: 0.44rem;  
  .details
    padding-bottom: 0.25rem
    background-color: #151d31
    .van-row:first-child
      padding: 0.2rem 0.4rem 0.3rem;
    .van-row
      padding: 0.1rem 0.4rem;
      color: #ffffff;
      font-size: 0.32rem;
      .van-col:nth-child(2)
        text-align: right;
    .van-row:nth-child(2)
      .van-col:nth-child(2)
        color:#ff0000
  .van-button--disabled
    background: #888 !important
    border-color: #888 !important
</style>
